/* eslint-disable react/prefer-es6-class */

import React from "react"
import createReactClass from "create-react-class"
import _ from "lodash"
import { findDOMNode } from "react-dom"
import { bool, oneOf, number, string, func, node, object } from "prop-types"
import * as Ladda from "ladda"

export default createReactClass({
  displayName: "Ladda",

  propTypes: {
    button: bool,
    transition: oneOf(["zoom-in", "contract", "expand-right"]),
    loading: bool.isRequired,
    progress: number,
    color: string,
    className: string,
    onClick: func,
    children: node.isRequired,
    style: object,
  },

  getDefaultProps() {
    return {
      button: false,
      color: "#878787",
      transition: "zoom-in",
      progress: 0,
      style: {},
    }
  },

  componentDidMount() {
    this.setState({ laddaInstance: Ladda.create(findDOMNode(this)) })
  },

  componentDidUpdate(prevProps) {
    this.propagateLaddaChange(prevProps)
  },

  handleClick(event) {
    if (this.props.onClick && this.isEnabled()) {
      this.props.onClick(event)
    }
  },

  isEnabled() {
    return !findDOMNode(this).attributes.getNamedItem("disabled")
  },

  propagateLaddaChange(prevProps) {
    if (prevProps.progress !== this.props.progress) {
      this.state.laddaInstance.setProgress(this.props.progress)
    }

    if (prevProps.loading !== this.props.loading) {
      const action = this.props.loading ? "start" : "stop"
      this.state.laddaInstance[action]()
      this.state.laddaInstance.setProgress(this.props.progress)
    }
  },

  getDelegatedProps() {
    const clones = _.omit(this.props, [
      "transition",
      "color",
      "loading",
      "children",
      "onClick",
      "button",
      "progress",
    ])
    const extended = _.extend(clones, {
      className: this.props.className ? `${this.props.className} ladda-button` : "ladda-button",
      "data-style": this.props.transition,
      onClick: this.handleClick,
    })

    if (this.props.color) {
      extended["data-spinner-color"] = this.props.color
    }

    if (this.props.loading) {
      extended.style = { ...this.props.style, cursor: "not-allowed" }
    }

    return extended
  },

  render() {
    return this.props.button ? (
      <button type="submit" {...this.getDelegatedProps()}>
        <span className="ladda-label">{this.props.children}</span>
      </button>
    ) : (
      <a {...this.getDelegatedProps()}>
        <span className="ladda-label">{this.props.children}</span>
      </a>
    )
  },
})
