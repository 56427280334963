function openHelperShelf(shelf) {
  shelf.style.display = "block"
  shelf.style.height = "0"

  requestAnimationFrame(() => {
    shelf.dataset.helperShelf = "open"
    shelf.style.height = `${shelf.scrollHeight}px`
  })
}

function closeHelperShelf(shelf) {
  shelf.style.height = `${shelf.offsetHeight}px`

  requestAnimationFrame(() => {
    shelf.style.height = "0"
    shelf.style.opacity = "0"
    shelf.dataset.helperShelf = "closed" // also sets margin animation

    setTimeout(() => {
      shelf.style.display = "none"
      shelf.style.opacity = "1"
    }, 300)
  })
}

function handleHelperShelfTriggerClick() {
  jQuery(document).on("click", "[data-helper-shelf-id]", (event) => {
    const shelf = document.getElementById(event.currentTarget.dataset.helperShelfId)

    const shelfWasClosed = shelf && shelf.dataset.helperShelf === "closed"

    const shelves = [...document.querySelectorAll(".helper-shelf[data-helper-shelf='open']")]

    shelves.forEach(closeHelperShelf)

    if (shelfWasClosed) {
      openHelperShelf(shelf)
    }
  })
}

function handleHelperShelfCloseClick() {
  jQuery(document).on("click", ".helper-shelf-close", (event) => {
    const shelf = jQuery(event.currentTarget).closest(".helper-shelf").get(0)

    shelf && closeHelperShelf(shelf)
  })
}

export function run() {
  handleHelperShelfTriggerClick()
  handleHelperShelfCloseClick()
}

export default exports
